import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useProductCardQuery from 'queries/useProductCardQuery';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import useEventListener from '@activebrands/core-web/hooks/useEventListener';
import useNotifications from '@activebrands/core-web/hooks/useNotifications';
import Events from '@activebrands/core-web/libs/Events';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { addBasketItem } from '@activebrands/core-web/state/basket/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { logger } from '@activebrands/core-web/utils/logger';
import StateButton from 'components/buttons/StateButton';
import ThemeButton from 'components/buttons/ThemeButton';
import StateIcon from 'components/icons/StateIcon';
import AddedToBasket from 'components/notifications/AddedToBasket';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Overlay = styled('div', {
    width: '100%',
    bottom: 0,
    color: 'var(--color-text-product-card)',
    backgroundColor: 'var(--color-bg-quickshop-overlay)',
    paddingBottom: '12px',
    scrollbarWidth: 'none', // Hide scrollbar in firefox

    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },

    [media.min['mobile.lg']]: {
        width: 'calc(100% - 2px)',
        bottom: '1px',
        left: '1px',
        overflowY: 'scroll',
    },
});

const Header = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    padding: '12px',
});

const StateIcons = styled('div', {
    position: 'relative',
});

const CurrentProduct = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
});

const Variations = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1px',
    maxHeight: '300px',
    overflowY: 'scroll',
    marginBottom: '16px',
    '::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in safari and chrome
    },
    scrollbarWidth: 'none', // Hide scrollbar in firefox
});

const Footer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0xp 8px',
});

const Description = styled('div', {
    width: '50%',
});

const QuickshopOverlay = ({
    $style = {},
    activePrice,
    addedToBasketAlgoliaData,
    addedToBasketData,
    color,
    isOpen,
    name,
    originalPrice,
    setButtonState = () => null,
    setIsOpen,
    uri,
    variations = [],
}) => {
    const path = useSelector(state => state.application.path);
    const dispatch = useTypedDispatch();
    const notifications = useNotifications();
    const [css] = useStyletron();
    const overlayRef = useRef();
    const { infoLabel, infoParagraph } = useProductCardQuery();
    const listener = useEventListener('window');

    const [selectedVariant, setSelectedVariant] = useState(null);
    const [addedToBasket, setAddedToBasket] = useState(false);

    const showPrice = activePrice && originalPrice;

    // Close on click if element isn't button or overlay child.
    useEffect(() => {
        let handler = null;

        if (isOpen && setIsOpen) {
            handler = listener.subscribe('click', e => {
                const overlay = overlayRef.current;
                e.stopPropagation();

                if (overlay && e.target !== overlay && overlay.contains(e.target)) {
                    return;
                }

                setIsOpen(false);
            });
        }

        return () => handler && listener.unsubscribe('click', handler);
    }, [isOpen]);

    const handleClick = async (e, variant) => {
        e.preventDefault();
        setButtonState('loading');
        setSelectedVariant(variant);

        try {
            await dispatch(addBasketItem(variant.id, variant.trackingMeta));
            setButtonState('success');
            setAddedToBasket(true);

            notifications.push(AddedToBasket, {
                ...addedToBasketData,
                size: variations.filter(item => item.id === variant.id)?.[0]?.name || '',
            });

            if (addedToBasketAlgoliaData) {
                Events.trigger(SearchEvents.PRODUCT_ADDED, addedToBasketAlgoliaData);
            }
        } catch (error) {
            logger.error(error);
            setButtonState('error');
            setAddedToBasket(false);
        } finally {
            setTimeout(() => setButtonState('default'), 2800);
            setTimeout(() => setAddedToBasket(false), 2800);
            setTimeout(() => setSelectedVariant(null), 2800);
        }
    };

    return (
        <Overlay $style={$style} ref={overlayRef}>
            <Header>
                <Heading as="h3" fontKeys="Primary/16_100_-05_500">
                    {fm('Add to cart')}
                </Heading>
                <StateIcons>
                    <button
                        className={css({
                            position: 'absolute',
                            right: 0,
                            display: ['flex', null, null, 'none'],
                        })}
                        type="button"
                        onClick={e => {
                            e.preventDefault();
                            overlay.close('quickshop');
                        }}
                    >
                        <StateIcon size="16px" state="close" />
                    </button>
                    <button
                        className={css({ display: ['none', null, null, 'flex'] })}
                        type="button"
                        onClick={e => {
                            e.preventDefault();
                            setIsOpen(false);
                        }}
                    >
                        <StateIcon size="16px" state="close" />
                    </button>
                </StateIcons>
            </Header>
            {name || showPrice ? (
                <CurrentProduct $style={{ padding: '0px 12px' }}>
                    <div>
                        {name && (
                            <Heading $style={{ marginBottom: '4px' }} fontKeys="Primary/14_100_-2_500">
                                {name}
                            </Heading>
                        )}
                        {color && (
                            <Paragraph $style={{ color: 'var(--color-text-subtle)' }} fontKeys="Misc/12_100_-1_500_UC">
                                {color}
                            </Paragraph>
                        )}
                    </div>
                    {showPrice && (
                        <Price
                            $style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
                            activePrice={activePrice}
                            fontStyling={{ ...textStyles['Primary/14_100_-3'] }}
                            originalPrice={originalPrice}
                        />
                    )}
                </CurrentProduct>
            ) : null}
            <Variations scrolldirection="horizontal">
                {variations.map(variant => {
                    const [hover, setHover] = useState(false);

                    let message = '';
                    let extraText = null;

                    const noPrice = variant.trackingMeta?.priceAsNumber === undefined;

                    // @todo: get few left from api
                    if (variant.fewLeft) {
                        message = `${fm('Few left')}`;
                    } else if (!variant.inStock || noPrice) {
                        message = `${fm('Out of stock')}`;
                    }

                    const isSelected = variant.id === selectedVariant?.id;
                    const stateIcon = isSelected ? 'loading' : 'default';

                    if (hover && !(isSelected && addedToBasket)) {
                        extraText = fm('Add to cart');
                    } else if (isSelected && addedToBasket) {
                        extraText = fm('Added to cart');
                    }

                    return (
                        <StateButton
                            $style={{
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '12px',
                                position: 'relative',
                                borderRight: 'none',
                                borderLeft: 'none',
                                marginBottom: '-1px',
                                borderTop: '1px solid var(--color-border-buttons-quickshop)',
                                borderBottom: '1px solid var(--color-border-buttons-quickshop)',
                                ':disabled': {
                                    textDecoration: 'line-through',
                                    borderTop: '1px solid var(--color-border-buttons-quickshop)',
                                },
                            }}
                            active={isSelected && addedToBasket}
                            disabled={!variant.inStock || noPrice}
                            extraText={extraText}
                            $extraTextStyles={{
                                color: 'var(--color-text-button-variation-active)',
                            }}
                            fontKeys="Primary/14_100_-2_500"
                            key={variant.name}
                            state={
                                isSelected && addedToBasket ? 'success' : variant.inStock || noPrice ? stateIcon : null
                            }
                            theme="variation"
                            type="button"
                            onClick={
                                variant.inStock
                                    ? e => {
                                          handleClick(e, variant);
                                          setTimeout(() => {
                                              if (setIsOpen) {
                                                  setIsOpen(false);
                                              }
                                              overlay.close('quickshop');
                                          }, 2800);
                                      }
                                    : undefined
                            }
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {variant.name}

                            <span
                                className={css({
                                    position: 'absolute',
                                    margin: '0 auto',
                                    left: '120px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    ...textStyles['Primary/12_100_-1'],
                                })}
                            >
                                {message}
                            </span>
                        </StateButton>
                    );
                })}
            </Variations>
            <Footer>
                <Description>
                    {infoLabel && (
                        <Heading as="h3" fontKeys="Primary/16_100_-05_500" $style={{ marginBottom: '4px' }}>
                            {infoLabel}
                        </Heading>
                    )}
                    {infoParagraph && (
                        <div className={css(textStyles['Primary/12_100_-1'])}>
                            <RichText data={infoParagraph} />
                        </div>
                    )}
                </Description>
                <ThemeButton
                    $style={{
                        alignSelf: 'flex-end',
                    }}
                    as="a"
                    fontKeys="Primary/16_100_-05"
                    size="sm"
                    theme="outlined"
                    href={`${path}${uri}`}
                >
                    {fm('Go to product')}
                </ThemeButton>
            </Footer>
        </Overlay>
    );
};

QuickshopOverlay.propTypes = {
    $style: PropTypes.object,
    activePrice: PropTypes.string,
    addedToBasketAlgoliaData: PropTypes.object,
    addedToBasketData: PropTypes.object,
    color: PropTypes.string,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    originalPrice: PropTypes.string,
    setButtonState: PropTypes.func,
    setIsOpen: PropTypes.func,
    uri: PropTypes.string,
    variations: PropTypes.array,
};

export default QuickshopOverlay;
